/** @jsx jsx */
import { jsx } from "theme-ui";
import React from "react";
import Divider from "../elements/divider";

import { useDeviceDetect } from "../elements/utils";
import Content from "../elements/content";
import Inner from "../elements/inner";
import RoadmapImg from "../../../assets/roadmap2.png";
import Vision from "../../../assets/vision.png";

const Roadmap = ({
  offset,
  factor = 1,
}: {
  offset: number;
  factor?: number;
}) => {
  const { isMobile } = useDeviceDetect();

  if (isMobile === null) {
    return null;
  }
  return (
    <div>
      <Content
        speed={0.6}
        offset={offset + 0.4}
        factor={factor}
        padding={isMobile ? 3 : undefined}
      >
        <Inner>
          <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", }}>
            <div
              sx={Object.assign(
                {
                  borderRadius: 30,
                  boxShadow: "2xl",
                  backgroundColor: "rgba(0, 0, 0, 0.8)",
                  width: "80%",
                },
                isMobile
                  ? {
                      paddingTop: 3,
                      paddingBottom: 2,
                      paddingLeft: 0.5,
                      paddingRight: 0.5,
                    }
                  : {
                      paddingTop: 4,
                      paddingBottom: 4,
                      paddingLeft: 5,
                      paddingRight: 5,
                    }
              )}
            >
              <img src={RoadmapImg} style={{ width: "100%" }} />
            </div>
            <div
              sx={Object.assign(
                {
                  borderRadius: 30,
                  boxShadow: "2xl",
                  backgroundColor: "rgba(0, 0, 0, 0.8)",
                  width: "80%",
                  marginTop: "6em",
                },
                isMobile
                  ? {
                      paddingTop: 3,
                      paddingBottom: 2,
                      paddingLeft: 0.5,
                      paddingRight: 0.5,
                    }
                  : {
                      paddingTop: 4,
                      paddingBottom: 4,
                      paddingLeft: 5,
                      paddingRight: 5,
                    }
              )}
            >
              <img src={Vision} style={{ width: "100%" }} />
            </div>
          </div>
        </Inner>
      </Content>
    </div>
  );
};

export default Roadmap;
