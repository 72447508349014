/** @jsx jsx */
import { jsx } from "theme-ui";
import React from "react";
import Content from "../elements/content";
import Inner from "../elements/inner";
import { useDeviceDetect } from "../elements/utils";

import Im1 from "../../../assets/merch/1.jpg";
import Im2 from "../../../assets/merch/2.jpg";
import Im3 from "../../../assets/merch/3.jpg";
import Im4 from "../../../assets/merch/4.jpg";
import Im5 from "../../../assets/merch/5.jpg";
import Im6 from "../../../assets/merch/6.jpg";
import Im7 from "../../../assets/merch/7.jpg";
import Im8 from "../../../assets/merch/8.jpg";
import Im9 from "../../../assets/merch/9.jpg";

import BackgroundImage from "../../../assets/poster.jpg";
import Divider from "../elements/divider";

const Merch = ({ offset, factor = 1 }: { offset: number; factor?: number }) => {
  const { isMobile } = useDeviceDetect();

  const imgSrcs = [Im1, Im2, Im3, Im4, Im5, Im6, Im7, Im8, Im9];

  const imgSX = { width: "30%", borderRadius: 10, boxShadow: "2xl" };
  const imgSXMob = {
    width: window.innerWidth * 0.85,
    borderRadius: 10,
    boxShadow: "2xl",
    marginBottom: "2em",
  };

  return (
    <div>
      <Divider
        bg="linear-gradient(220deg, rgb(112, 236, 157) 11.64%, rgb(112, 126, 255) 70.29%, rgb(129, 60, 255) 114.24%)" // bg="linear-gradient(to right, SlateBlue 0%, DeepSkyBlue 100%)"
        clipPath={
          isMobile
            ? "polygon(0 5%, 100% 0%, 100% 50%, 0 55%)"
            : "polygon(0 10%, 100% 0%, 100% 40%, 0 50%)"
        }
        speed={isMobile ? -0.1 : -0.1}
        offset={isMobile ? offset - 5 : offset + 0.5}
        factor={factor}
      />
      <Content
        speed={0.6}
        offset={offset}
        factor={factor}
        padding={isMobile ? 0 : 0}
      >
        <div
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: [0, "2em", 0, "2em"],
          }}
        >
          <div
            style={{
              fontSize: "58px",
              color: "black",
              textAlign: "center",
              // margin: "0.5em",
            }}
          >
            <h2 style={{ margin: 0 }}>Merch</h2>
          </div>
          <div
            sx={{
              color: "black",
              fontSize: isMobile ? "14pt" : "16pt",
              fontWeight: "400",
              textAlign: "center",
              lineHeight: isMobile ? 1.1 : undefined,
              // width: isMobile ? undefined : "70%",
              width: [`90%`, `90%`, `90%`, `90%`, `80%`, `66%`],
              // marginTop: isMobile ? "1em" : undefined,
            }}
          >
            We're building a unique merch store for each member of the
            Endangered Sounds community - featuring designs with the Soundprints
            you personally own. Sneak peek below 🤫
          </div>
          {isMobile ? (
            <div
              style={{
                marginTop: isMobile ? "2em" : "6em",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img sx={imgSXMob} src={Im1} />
              <img sx={imgSXMob} src={Im2} />
              <img sx={imgSXMob} src={Im3} />
              <img sx={imgSXMob} src={Im4} />
              <img sx={imgSXMob} src={Im5} />
              <img sx={imgSXMob} src={Im6} />
              <img sx={imgSXMob} src={Im7} />
              <img sx={imgSXMob} src={Im8} />
              <img sx={imgSXMob} src={Im9} />
            </div>
          ) : (
            <div
              style={{
                marginTop: "6em",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                }}
              >
                <img sx={imgSX} src={Im1} />
                <img sx={imgSX} src={Im2} />
                <img sx={imgSX} src={Im3} />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                  marginTop: "2em",
                }}
              >
                <img sx={imgSX} src={Im4} />
                <img sx={imgSX} src={Im5} />
                <img sx={imgSX} src={Im6} />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                  marginTop: "2em",
                }}
              >
                <img sx={imgSX} src={Im7} />
                <img sx={imgSX} src={Im8} />
                <img sx={imgSX} src={Im9} />
              </div>
            </div>
          )}
        </div>
      </Content>
    </div>
  );
};

export default Merch;
