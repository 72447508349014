/** @jsx jsx */
import { jsx } from "theme-ui";
import React from "react";
import Divider from "../elements/divider";
import Inner from "../elements/inner";
import Content, { HeroContent } from "../elements/content";
import Svg from "./svg";
import { UpDown, UpDownWide } from "../styles/animations";
// @ts-ignore
import Intro from "../sections/intro";
import "@fontsource/chakra-petch";

import Video from "../../../assets/video.mp4";
import Logo from "../../../assets/logo.png";
import { useDeviceDetect } from "../elements/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVolumeOff, faVolumeUp } from "@fortawesome/free-solid-svg-icons";
import B64Video from "./b64_video";
import SidebarWidthContext from "../elements/context";

const Hero = ({ offset, factor = 1 }: { offset: number; factor?: number }) => {
  const { isMobile } = useDeviceDetect();
  const [isMuted, setIsMuted] = React.useState(true);
  const sidebarWidth = React.useContext(SidebarWidthContext);
  return (
    <div>
      <Divider
        speed={0.2}
        offset={offset}
        factor={factor}
        bg="linear-gradient(white, 95%, #F8FAFC)"
      >
        {/* <UpDown>
        <Svg
          icon="triangle"
          hiddenMobile
          width={48}
          stroke
          color="icon_orange"
          left="10%"
          top="20%"
        />
        <Svg
          icon="hexa"
          width={48}
          stroke
          color="icon_red"
          left="60%"
          top="70%"
        />
        <Svg icon="box" width={6} color="icon_darker" left="60%" top="15%" />
      </UpDown>
      <UpDownWide>
        <Svg
          icon="arrowUp"
          hiddenMobile
          width={16}
          color="icon_blue"
          left="80%"
          top="10%"
        />
        <Svg
          icon="triangle"
          width={12}
          stroke
          color="icon_brightest"
          left="90%"
          top="50%"
        />
        <Svg
          icon="circle"
          width={16}
          color="icon_darker"
          left="70%"
          top="90%"
        />
        <Svg
          icon="triangle"
          width={16}
          stroke
          color="icon_darkest"
          left="30%"
          top="65%"
        />
        <Svg
          icon="cross"
          width={16}
          stroke
          color="icon_pink"
          left="28%"
          top="15%"
        />
        <Svg
          icon="circle"
          width={6}
          color="icon_darkest"
          left="75%"
          top="10%"
        />
        <Svg
          icon="upDown"
          hiddenMobile
          width={8}
          color="icon_darkest"
          left="45%"
          top="10%"
        />
      </UpDownWide>
      <Svg
        icon="circle"
        hiddenMobile
        width={24}
        color="icon_darker"
        left="5%"
        top="70%"
      />
      <Svg icon="circle" width={6} color="icon_darkest" left="4%" top="20%" />
      <Svg icon="circle" width={12} color="icon_darkest" left="50%" top="60%" />
      <Svg icon="upDown" width={8} color="icon_darkest" left="95%" top="90%" />
      <Svg
        icon="upDown"
        hiddenMobile
        width={24}
        color="icon_darker"
        left="40%"
        top="80%"
      />
      <Svg
        icon="triangle"
        width={8}
        stroke
        color="icon_darker"
        left="25%"
        top="5%"
      />
      <Svg icon="circle" width={64} color="icon_green" left="95%" top="5%" />
      <Svg
        icon="box"
        hiddenMobile
        width={64}
        color="icon_purple"
        left="5%"
        top="90%"
      />
      <Svg icon="box" width={6} color="icon_darkest" left="10%" top="10%" />
      <Svg icon="box" width={12} color="icon_darkest" left="40%" top="30%" />
      <Svg
        icon="hexa"
        width={16}
        stroke
        color="icon_darker"
        left="10%"
        top="50%"
      />
      <Svg
        icon="hexa"
        width={8}
        stroke
        color="icon_darker"
        left="80%"
        top="70%"
      /> */}
      </Divider>
      <HeroContent
        sx={{ variant: `texts.bigger` }}
        speed={0.4}
        offset={offset}
        factor={factor}
        padding={[0, 0, 0, 0]}
        justifyContent="top"
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "start",
            height: "100%",
            // border: "1px solid red",
            marginLeft: `${sidebarWidth}%`,
          }}
        >
          <video
            autoPlay
            muted={isMuted}
            style={
              isMobile
                ? { objectFit: "cover", width: "100%", maxWidth: "100%" }
                : { objectFit: "cover", height: "60%" }
            }
            // loop
          >
            {/* <source src={Video} type="video/mp4" /> */}
            <B64Video />
          </video>
          {/* <button
            sx={{
              border: "1px solid rgba(0, 0, 0, 0.05)",
              boxShadow: "xl",
              borderRadius: 30,
              padding: "0.75em",
              paddingLeft: "1.5em",
              paddingRight: "1.5em",
            }}
            onClick={() => setIsMuted(!isMuted)}
          >
            <FontAwesomeIcon
              icon={isMuted ? faVolumeOff : faVolumeUp}
              style={{ color: "rgba(0, 0, 0, 0.2)" }}
              size="3x"
            />
          </button> */}
          <FontAwesomeIcon
            icon={isMuted ? faVolumeOff : faVolumeUp}
            size="2x"
            onClick={() => setIsMuted(!isMuted)}
            style={{
              color: "rgba(150, 150, 150, 1)",
              filter: "drop-shadow(4px 4px 4px #00000055)",
              justifySelf: "start",
              marginTop: isMobile ? "-0.2em" : "-0.7em",
            }}
          />
          <div
            style={{
              // height: "40%",
              display: "flex",
              alignItems: "end",
              justifyContent: "center",
              // marginTop: "auto",
              marginBottom: "1.5em",
              marginTop:  isMobile ? "2em" : "4em",
            }}
          >
            <img
              src={Logo}
              style={isMobile ? { width: "90%" } : { height: "9vh" }}
            />
          </div>
          <div
            style={{
              width: "80%",
              textAlign: "center",
              marginBottom: "2em",
              fontWeight: "bold",
              lineHeight: isMobile ? 1.2 : undefined,
            }}
          >
            Immortalize the calls of endangered animals with the first
            audio-visualization NFT on Solana.
          </div>
        </div>
        {/* <Inner>
        <Intro />
      </Inner> */}
        {/* <h1
        style={{
          // fontFamily: "Chakra Petch",
          fontFamily: "Verdana",
          fontWeight: "bold",
          fontSize: isMobile() ? "1em" : "4em",
        }}
      >
        ENDANGERED SOUNDS
      </h1> */}
      </HeroContent>
    </div>
  );
};

export default Hero;
