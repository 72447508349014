import * as React from "react";
import { Parallax, ParallaxLayer, IParallax } from "@react-spring/parallax";
import Layout from "../components/layout";
import Hero from "../components/hero";
import Projects from "../components/projects";
import About from "../components/about";
import Contact from "../components/contact";
import Charity from "../components/charity";
import Roadmap from "../components/roadmap";
import { useDeviceDetect } from "../elements/utils";
import { useColorMode } from "theme-ui";
import Sidebar from "../components/sidebar";
import SidebarWidthContext from "../elements/context";
import Merch from "../components/merch";

export class Page {
  elt: React.JSXElementConstructor<any>;
  name: string;
  desktopLen: number;
  mobileLen: number;
  mobileScrollCorr: number;

  constructor(
    elt: React.JSXElementConstructor<any>,
    name: string,
    desktopLen: number,
    mobileLen: number,
    mobileScrollCorr: number,
  ) {
    this.elt = elt;
    this.name = name;
    this.desktopLen = desktopLen;
    this.mobileLen = mobileLen;
    this.mobileScrollCorr = mobileScrollCorr;
  }
}

const Cara = () => {
  const { isMobile } = useDeviceDetect();
  const [colorMode, setColorMode] = useColorMode();
  const SIDEBAR_WIDTH = React.useMemo(() => (isMobile ? 100 : 20), [isMobile]);
  const [sidebarWidth, setSidebarWidth] = React.useState(null);
  const parallaxRef = React.useRef<IParallax>();
  React.useEffect(() => {
    setColorMode("light");
  }, [setColorMode]);
  React.useEffect(() => {
    setSidebarWidth(isMobile ? 0 : SIDEBAR_WIDTH);
  }, [isMobile, SIDEBAR_WIDTH]);
  console.log("isMobile", isMobile, sidebarWidth, SIDEBAR_WIDTH);

  console.log(parallaxRef.current!);

  // const set = React.useMemo(() => {
  //   return () => {
  //     setSidebarWidth(0)
  //   }
  // }, []);

  if (isMobile === null) {
    return null;
  }

  const pages = [
    new Page(Hero, "Home", 1, 1, 0),
    new Page(Projects, "Collection", 1, 3, 0.1),
    new Page(Roadmap, "Roadmap", 2, 2, 0.1),
    new Page(Charity, "Conservation", 1, 1, 0),
    new Page(About, "Art", 1, 2, -0.2),
    new Page(Merch, "Merch", 2, 4, -0.5),
  ];

  const cumSum = (
    (sum) => (value) =>
      (sum += value)
  )(0);
  const offsets = isMobile
    ? [0, ...pages.map((page) => page.mobileLen).map(cumSum)]
    : [0, ...pages.map((page) => page.desktopLen).map(cumSum)];

  return (
    <Layout>
      <SidebarWidthContext.Provider value={sidebarWidth}>
        <Parallax pages={offsets[offsets.length - 1]} ref={parallaxRef}>
          <ParallaxLayer
            offset={0}
            sticky={{ start: 0, end: 8.8 }}
            style={{
              // border: "1px solid red",
              width: `${sidebarWidth}%`,
            }}
          >
            <Sidebar
              onClose={() => setSidebarWidth(0)}
              onOpen={() => setSidebarWidth(SIDEBAR_WIDTH)}
              parallaxRef={parallaxRef}
              pages={pages}
            />
          </ParallaxLayer>
          {pages.map((page, idx) => (
            <page.elt
              offset={offsets[idx]}
              factor={isMobile ? page.mobileLen : page.desktopLen}
            />
          ))}
          {/* <Hero offset={0} factor={1} />
          <Projects offset={1} factor={isMobile ? 3 : 1} />
          <Roadmap offset={isMobile ? 5 : 2} factor={isMobile ? 2 : 1} />
          <About offset={isMobile ? 7 : 3} factor={isMobile ? 2 : 1} />
          <Merch offset={isMobile ? 9 : 5} factor={isMobile ? 4 : 2} /> */}
          {/* <Charity offset={6} factor={1} /> */}
        </Parallax>
      </SidebarWidthContext.Provider>
    </Layout>
  );
};

export default Cara;
