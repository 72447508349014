/** @jsx jsx */
import { jsx } from "theme-ui";
import Content from "../elements/content";
import Divider from "../elements/divider";
import Inner from "../elements/inner";
import { UpDown, UpDownWide } from "../styles/animations";
import Svg from "./svg";
import BackgroundImage from "../../../assets/wildlife.jpg";
import PartnerImage from "../../../assets/partner.jpg";
import ConservationMDX from "../sections/conservation";
import { useDeviceDetect } from "../elements/utils";

const Charity = ({ offset, factor }: { offset: number; factor: number }) => {
  const { isMobile } = useDeviceDetect();

  return (
    <div>
      <Divider
        bg={`url("${BackgroundImage}")`}
        clipPath="polygon(0 15%, 100% 0%, 100% 45%, 0 60%)"
        speed={-0.2}
        offset={offset + 0.3}
        factor={factor}
      />
      <Content speed={0.4} offset={offset} factor={factor}>
        <Inner>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              sx={Object.assign(
                {
                  borderRadius: 30,
                  boxShadow: "2xl",
                  backgroundColor: "rgba(255, 255, 255)",
                  width: "95%",
                },
                isMobile
                  ? {
                      paddingTop: 3,
                      paddingBottom: 2,
                      paddingLeft: 2,
                      paddingRight: 2,
                    }
                  : {
                      paddingTop: 4,
                      paddingBottom: 4,
                      paddingLeft: 5,
                      paddingRight: 5,
                    }
              )}
            >
              <img src={PartnerImage} style={{ width: "100%" }} />
            </div>
          </div>
        </Inner>
      </Content>
    </div>
  );
  return (
    <div>
      {/* <Divider
      bg="green"
      speed={0.2}
      offset={offset}
      factor={factor}
    /> */}
      <Divider
        //   bg="linear-gradient(to right, SlateBlue 0%, DeepSkyBlue 100%)"
        bg={`url("${BackgroundImage}")`}
        clipPath="polygon(0 15%, 100% 0%, 100% 45%, 0 60%)"
        speed={-0.1}
        offset={offset + 0.3}
        factor={factor}
      />
      <Content speed={0.4} offset={offset} factor={factor} padding={undefined}>
        <Inner>
          <div
            sx={{
              backgroundColor: "rgba(255, 255, 255, 0.9)",
              borderRadius: isMobile ? 0 : 30,
              clipPath: "polygon(0, 15% 100% 0% 100% 85% 0 75%)",
              paddingBottom: 5,
              paddingTop: 3,
              boxShadow: "2xl",
              // borderRadius: 30,
            }}
          >
            <div style={{ paddingLeft: "5%", paddingRight: "5%" }}>
              <ConservationMDX />
            </div>
          </div>
        </Inner>
      </Content>
      {/* <Divider speed={0.1} offset={offset + 0.6} factor={factor}> */}
      {/* <UpDown>
        <Svg icon="box" width={6} color="icon_brightest" left="85%" top="75%" />
        <Svg icon="upDown" width={8} color="icon_teal" left="70%" top="20%" />
        <Svg
          icon="triangle"
          width={8}
          stroke
          color="icon_orange"
          left="25%"
          top="5%"
        />
        <Svg
          icon="circle"
          hiddenMobile
          width={24}
          color="icon_brightest"
          left="17%"
          top="60%"
        />
      </UpDown>
      <UpDownWide>
        <Svg
          icon="arrowUp"
          hiddenMobile
          width={16}
          color="icon_green"
          left="20%"
          top="90%"
        />
        <Svg
          icon="triangle"
          width={12}
          stroke
          color="icon_brightest"
          left="90%"
          top="30%"
        />
        <Svg
          icon="circle"
          width={16}
          color="icon_yellow"
          left="70%"
          top="90%"
        />
        <Svg
          icon="triangle"
          hiddenMobile
          width={16}
          stroke
          color="icon_teal"
          left="18%"
          top="75%"
        />
        <Svg
          icon="circle"
          width={6}
          color="icon_brightest"
          left="75%"
          top="10%"
        />
        <Svg
          icon="upDown"
          hiddenMobile
          width={8}
          color="icon_green"
          left="45%"
          top="10%"
        />
      </UpDownWide>
      <Svg
        icon="circle"
        hiddenMobile
        width={6}
        color="icon_brightest"
        left="4%"
        top="20%"
      />
      <Svg icon="circle" width={12} color="icon_pink" left="80%" top="60%" />
      <Svg icon="box" width={6} color="icon_orange" left="10%" top="10%" />
      <Svg icon="box" width={12} color="icon_yellow" left="29%" top="26%" />
      <Svg
        icon="hexa"
        width={16}
        stroke
        color="icon_red"
        left="75%"
        top="30%"
      />
      <Svg
        icon="hexa"
        width={8}
        stroke
        color="icon_yellow"
        left="80%"
        top="70%"
      /> */}
      {/* </Divider> */}
    </div>
  );
};

export default Charity;
