import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Giving Back`}</h2>
    <blockquote>
      <p parentName="blockquote">{`We are excited to announce that we will be donating $200,000 of our proceeds from the NFT drop, as well as 25% of our royalties from NFT resale, towards wildlife conservation.`}</p>
    </blockquote>
    <p>{`By launching the first charity-oriented NFT on Solana, we aim to prove that blockchain technology, and especially Solana, can be a force for good. The excessive energy consumption of Bitcoin's proof of work algorithm has created a lot of (well deserved) bad press. Solana utilises more modern consensus algorithms, and is vastly more environmentally friendly. We believe that this NFT can generate positive press around Solana and help propel this technology to the mainstream.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      