/** @jsx jsx */
import { jsx } from "theme-ui";

import * as React from "react";
import Divider from "../elements/divider";
import Inner from "../elements/inner";
import Content from "../elements/content";
import Svg from "./svg";
import { UpDown, UpDownWide } from "../styles/animations";
// @ts-ignore
import AboutMDX from "../sections/about";
import InfographicHorizontal from "../../../assets/info_horizontal.png";
import InfographicVertical from "../../../assets/info_vertical.png";
import { useDeviceDetect } from "../elements/utils";

const About = ({ offset, factor = 1 }: { offset: number; factor?: number }) => {
  const { isMobile } = useDeviceDetect();
  return (
    <div>
      <Divider
        bg="divider"
        clipPath="polygon(0 16%, 100% 4%, 100% 82%, 0 94%)"
        speed={0.2}
        offset={offset}
        factor={factor}
      />
      <Divider speed={0.1} offset={offset} factor={factor}>
        <UpDown>
          <Svg
            icon="box"
            hiddenMobile
            width={6}
            color="icon_blue"
            left="50%"
            top="75%"
          />
          <Svg
            icon="upDown"
            hiddenMobile
            width={8}
            color="icon_darkest"
            left="70%"
            top="20%"
          />
          <Svg
            icon="triangle"
            width={8}
            stroke
            color="icon_darkest"
            left="25%"
            top="5%"
          />
          <Svg
            icon="upDown"
            hiddenMobile
            width={24}
            color="icon_orange"
            left="80%"
            top="80%"
          />
        </UpDown>
        <UpDownWide>
          <Svg
            icon="arrowUp"
            hiddenMobile
            width={16}
            color="icon_purple"
            left="5%"
            top="80%"
          />
          <Svg
            icon="triangle"
            width={12}
            stroke
            color="icon_brightest"
            left="95%"
            top="50%"
          />
          <Svg
            icon="circle"
            hiddenMobile
            width={6}
            color="icon_brightest"
            left="85%"
            top="15%"
          />
          <Svg
            icon="upDown"
            hiddenMobile
            width={8}
            color="icon_darkest"
            left="45%"
            top="10%"
          />
        </UpDownWide>
        <Svg
          icon="circle"
          hiddenMobile
          width={6}
          color="icon_brightest"
          left="4%"
          top="20%"
        />
        <Svg
          icon="circle"
          width={12}
          color="icon_darkest"
          left="70%"
          top="60%"
        />
        <Svg icon="box" width={6} color="icon_orange" left="10%" top="10%" />
        <Svg icon="box" width={12} color="icon_darkest" left="20%" top="30%" />
        <Svg
          icon="hexa"
          width={8}
          stroke
          color="icon_darkest"
          left="80%"
          top="70%"
        />
      </Divider>
      {/* <Content speed={0.4} offset={offset} factor={1}>
      <Inner>
        <AboutMDX />
      </Inner>
    </Content> */}
      <Content
        speed={0.7}
        offset={offset}
        factor={1}
        padding={isMobile ? 3 : undefined}
      >
        <Inner>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", }}>
            <div
              sx={Object.assign(
                {
                  // border: "1px solid gray",
                  borderRadius: 30,
                  boxShadow: "2xl",
                  backgroundColor: "rgba(255, 255, 255, 0.95)",
                  width: "95%",
                },
                isMobile
                  ? {
                      paddingTop: 3,
                      paddingBottom: 2,
                      paddingLeft: 2,
                      paddingRight: 2,
                    }
                  : {
                      paddingTop: 4,
                      paddingBottom: 4,
                      paddingLeft: 5,
                      paddingRight: 5,
                    }
              )}
              // className={"shadow-2xl"}
            >
              {isMobile ? (
                <img src={InfographicVertical} style={{ width: "100%" }} />
              ) : (
                <img src={InfographicHorizontal} style={{ width: "100%" }} />
              )}
            </div>
          </div>
        </Inner>
      </Content>
    </div>
  );
};

export default About;
