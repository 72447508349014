import React from "react";

import Home from "./Home";

import * as anchor from "@project-serum/anchor";
import { clusterApiUrl } from "@solana/web3.js";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import {
  getPhantomWallet,
  getSlopeWallet,
  getSolflareWallet,
  getSolletWallet,
  getSolletExtensionWallet,
} from "@solana/wallet-adapter-wallets";

import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";

import { WalletDialogProvider } from "@solana/wallet-adapter-material-ui";
import { createTheme, ThemeProvider } from "@material-ui/core";

const process = {
  env: {
    REACT_APP_CANDY_MACHINE_CONFIG: "CUfxmiAEpBgaVpbf7cRdGui59qCbVkcY7r5wRY9GFeQa",
    REACT_APP_CANDY_MACHINE_ID: "6ztQfw2NYMteYRq3tX1fpUVz5DCQUW3b2gvTgfqubChQ",
    REACT_APP_TREASURY_ADDRESS: "Gqy1j8emuQQzqqGgodujCQSCdHuF6T3fGw5fBECTh9PN",
    REACT_APP_CANDY_START_DATE: "1635721200",
    REACT_APP_SOLANA_NETWORK: "mainnet-beta",
    REACT_APP_SOLANA_RPC_HOST: "https://solana-api.projectserum.com",
  },
};

const treasury = new anchor.web3.PublicKey(
  process.env.REACT_APP_TREASURY_ADDRESS!
);

const config = new anchor.web3.PublicKey(
  process.env.REACT_APP_CANDY_MACHINE_CONFIG!
);

const candyMachineId = new anchor.web3.PublicKey(
  process.env.REACT_APP_CANDY_MACHINE_ID!
);

const network = process.env.REACT_APP_SOLANA_NETWORK as WalletAdapterNetwork;

const rpcHost = process.env.REACT_APP_SOLANA_RPC_HOST!;
const connection = new anchor.web3.Connection(rpcHost);

const startDateSeed = parseInt(process.env.REACT_APP_CANDY_START_DATE!, 10);

const txTimeout = 30000; // milliseconds (confirm this works for your project)

const theme = createTheme({
  palette: {
    type: "dark",
  },
  overrides: {
    MuiButtonBase: {
      root: {
        // justifyContent: "flex-start",
        color: "white",
      },
    },
    MuiButton: {
      root: {
        textTransform: undefined,
        // padding: "12px 16px",
        textAlign: "center",
      },
      startIcon: {
        marginRight: 8,
      },
      endIcon: {
        marginLeft: 8,
      },
    },
  },
});

const MintButton = () => {
  const endpoint = React.useMemo(() => clusterApiUrl(network), []);

  const wallets = React.useMemo(
    () => [
      getPhantomWallet(),
      getSlopeWallet(),
      getSolflareWallet(),
      getSolletWallet({ network }),
      getSolletExtensionWallet({ network }),
    ],
    []
  );

  return (
    <ThemeProvider theme={theme}>
      <ConnectionProvider endpoint={endpoint}>
        <WalletProvider wallets={wallets} autoConnect={true}>
          <WalletDialogProvider>
            <Home
              candyMachineId={candyMachineId}
              config={config}
              connection={connection}
              startDate={startDateSeed}
              treasury={treasury}
              txTimeout={txTimeout}
            />
          </WalletDialogProvider>
        </WalletProvider>
      </ConnectionProvider>
    </ThemeProvider>
  );
};

export default MintButton;
