/** @jsx jsx */
import { jsx } from "theme-ui";
import { useDeviceDetect } from "../elements/utils";

// import "video-react/dist/video-react.css";
// import { Player, BigPlayButton } from "video-react";

const Sample = ({
  src,
  poster,
  isEnd = false,
}: {
  src: string;
  poster: string;
  isEnd?: boolean;
}) => {
  const { isMobile } = useDeviceDetect();
  return (
    <div
      sx={{
        marginRight: isMobile ? "1em": (isEnd ? 0 : 4),
        marginLeft: isMobile ? "1em" : undefined,
        marginTop: 4,
      }}
    >
      {/* <Player src={src} width={500} poster={poster}>
      <BigPlayButton position="center" />
    </Player> */}
      <video
        // muted
        sx={{ width: "100%", borderRadius: 10, boxShadow: "2xl" }}
        poster={poster}
        controls
      >
        <source src={src} type="video/mp4" />
      </video>
    </div>
  );
};

export default Sample;
