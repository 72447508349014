/** @jsx jsx */
import { jsx } from "theme-ui";
import React, { useContext, useEffect } from "react";
import { ParallaxLayer, IParallax } from "@react-spring/parallax";
import Content from "../elements/content";
import SidebarWidthContext from "../elements/context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTwitter,
  faDiscord,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";

import Logo from "../../../assets/logo_white.png";
import {
  faAlignJustify,
  faAngleRight,
  faBars,
  faGreaterThan,
  faPlay,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { useDeviceDetect } from "../elements/utils";
import Countdown from "./countdown";

import { Page } from "../templates/cara";
import MintButton from "./mint/mint";

const SectionLink = ({
  secName,
  isOnScreen,
  scrollToSec,
  onClose,
}: {
  secName: string;
  isOnScreen: boolean;
  scrollToSec: () => void;
  onClose: () => void;
}) => {
  const { isMobile } = useDeviceDetect();

  return (
    <div
      sx={{
        fontSize: "22",
        fontWeight: "bold",
        color: "white",
        marginTop: "0.5em",
        marginBottom: "0.5em",
        "&:hover": {
          cursor: "pointer",
        },
      }}
      onClick={() => {
        if (isMobile) {
          onClose();
        }
        scrollToSec();
      }}
    >
      <FontAwesomeIcon icon={isOnScreen ? faPlay : faAngleRight} />
      <span
        style={{ marginLeft: "0.7em", fontWeight: isOnScreen ? "800" : "bold" }}
      >
        {secName}
      </span>
    </div>
  );
};

const Sidebar = ({
  onClose,
  onOpen,
  parallaxRef,
  pages,
}: {
  onClose: () => any;
  onOpen: () => any;
  parallaxRef: React.RefObject<IParallax>;
  pages: Page[];
}) => {
  const sidebarWidth = useContext(SidebarWidthContext);
  const [currentPage, setCurrentPage] = React.useState(0);

  const { isMobile } = useDeviceDetect();

  // const dropTimeUTC = new Date("October 31, 2021 23:00:00");
  const dropTimeUTC = new Date("2021-10-31T23:00:00.000Z");

  useEffect(() => {
    const interval = setInterval(() => {
      const page =
        parallaxRef.current &&
        parallaxRef.current!.current / parallaxRef.current!.space;
      setCurrentPage(page);
    }, 100);
    return () => clearInterval(interval);
  }, []);

  const preventTouchMove = React.useCallback((e) => e.preventDefault(), []);

  useEffect(() => {
    if (isMobile && sidebarWidth != 0) {
      console.log("adding touchmove event listener");
      document.body.addEventListener("touchmove", preventTouchMove, {
        passive: false,
      });
    } else {
      document.body.removeEventListener("touchmove", preventTouchMove);
    }

    return () => window.removeEventListener("touchmove", preventTouchMove);
  }, [isMobile, sidebarWidth]);

  const scrollTo = parallaxRef.current && parallaxRef.current!.scrollTo;

  if (sidebarWidth === 0) {
    return (
      <span
        style={{
          fontSize: "1.8em",
          backgroundColor: "white",
          // border: "1px solid red",
          display: "flex",
          // width: "2em",
          // height: "2em",
          borderRadius: 10,
        }}
      >
        <FontAwesomeIcon
          icon={faBars}
          style={{
            marginLeft: 10,
            marginTop: 10,
          }}
          onClick={onOpen}
          sx={{
            "&:hover": {
              cursor: "pointer",
            },
          }}
        />
      </span>
    );
  }

  const cumSum = (
    (sum) => (value) =>
      (sum += value)
  )(0);
  const offsets = isMobile
    ? [0, ...pages.map((page) => page.mobileLen).map(cumSum)]
    : [0, ...pages.map((page) => page.desktopLen).map(cumSum)];

  return (
    <div
      sx={{
        // borderRight: "1px solid rgb(230, 230, 230)",
        backgroundColor: "#333333",
        height: isMobile ? window.innerHeight : "100%",
        // width: `${sidebarWidth}%`,
        boxShadow: "2xl",
        // borderRight: "1 px solid black"
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        position: "fixed",
        overflowX: "hidden",
        width: "100%",
      }}
    >
      <div
        style={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
            width: "100%",
            fontSize: "1.8em",
            borderBottom: "1px solid black",
          }}
        >
          <FontAwesomeIcon
            icon={faTimes}
            color="white"
            style={{
              margin: 10,
            }}
            onClick={onClose}
            sx={{
              "&:hover": {
                cursor: "pointer",
              },
            }}
          />
        </div>
        <div
          style={{
            borderBottom: "1px solid black",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img
            src={Logo}
            style={{
              width: "90%",
              marginTop: "1em",
              marginBottom: "1em",
            }}
          />
        </div>
        {/* <div
          style={{
            marginTop: "2em",
          }}
        >
          <Countdown till={dropTimeUTC} />
        </div> */}
        {/* <button
          sx={{
            padding: "1em",
            borderRadius: 5,
            border: "none",
            background:
              "linear-gradient(220deg, rgb(112, 236, 157) 11.64%, rgb(112, 126, 255) 70.29%, rgb(129, 60, 255) 114.24%)",
            color: "white",
            fontWeight: "bold",
            width: "70%",
            marginTop: "2em",
            marginBottom: "3em",
            "&:hover": {
              cursor: "pointer",
            },
            fontSize: "1em",
          }}
          onClick={() => alert("Coming soon :)")}
        >
          Connect wallet
        </button> */}
      </div>
      <div
        style={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <MintButton />
      </div>
      <div
        style={{
          marginLeft: "10%",
        }}
      >
        {pages.map((page, idx) => (
          <SectionLink
            secName={page.name}
            scrollToSec={() =>
              scrollTo(
                isMobile ? offsets[idx] + page.mobileScrollCorr : offsets[idx]
              )
            }
            onClose={onClose}
            isOnScreen={
              currentPage >= offsets[idx] && currentPage < offsets[idx + 1]
            }
          />
        ))}
        {/* <SectionLink
          secName="Home"
          scrollToSec={() => scrollTo(0)}
          onClose={onClose}
          isOnScreen={currentPage <= 0.8}
        />
        <SectionLink
          secName="Collection"
          scrollToSec={() => scrollTo(isMobile ? 1 : 1.1)}
          onClose={onClose}
          isOnScreen={
            isMobile
              ? currentPage > 0.8 && currentPage <= 5
              : currentPage > 1 && currentPage <= 2
          }
        />
        <SectionLink
          secName="Roadmap"
          scrollToSec={() => scrollTo(isMobile ? 5.2 : 2.1)}
          onClose={onClose}
          isOnScreen={
            isMobile
              ? currentPage > 5 && currentPage <= 7
              : currentPage > 2 && currentPage <= 2.5
          }
        />
        <SectionLink
          secName="Art"
          scrollToSec={() => scrollTo(isMobile ? 6.9 : 3.1)}
          onClose={onClose}
          isOnScreen={isMobile ? currentPage > 7 : currentPage > 2.5}
        /> */}
        {/* <SectionLink
          secName="Wildlife Conservation"
          scrollToSec={() => scrollTo(6)}
          onClose={onClose}
          isOnScreen={currentPage > 6}
        /> */}
        {/* <div
          style={{ marginBottom: "1em" }}
          onClick={() => {
            onClose();
            scrollTo(1);
          }}
        >
          {"> Samples"}
        </div>
        <div style={{ marginBottom: "1em" }} onClick={() => scrollTo(3)}>
          {"> The Collection"}
        </div>
        <div style={{ marginBottom: "1em" }} onClick={() => scrollTo(5)}>
          {"> Giving Back"}
        </div>
        <div style={{ marginBottom: "1em" }} onClick={() => scrollTo(7)}>
          {"> Roadmap"}
        </div> */}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "1em",
        }}
      >
        <a href="https://discord.gg/PQyxHS4Dku">
          <FontAwesomeIcon
            icon={faDiscord}
            style={{ color: "white", margin: "0.5em" }}
            size="2x"
          />
        </a>
        <a href="https://twitter.com/EndangeredSol">
          <FontAwesomeIcon
            icon={faTwitter}
            style={{
              color: "white",
              margin: "0.5em",
            }}
            size="2x"
          />
        </a>
        {/* <p style={{color: "white"}}>Twitter</p> */}
        <a href="https://www.instagram.com/endangeredsounds/">
          <FontAwesomeIcon
            icon={faInstagram}
            style={{
              color: "white",
              margin: "0.5em",
            }}
            size="2x"
          />
        </a>
      </div>
    </div>
  );
};

export default Sidebar;
