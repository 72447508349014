/** @jsx jsx */
import { jsx } from "theme-ui";
import React from "react";
// import Divider from "../elements/divider";
// import Inner from "../elements/inner";
// import Content from "../elements/content";
// import Svg from "./svg";
// import { UpDown, UpDownWide } from "../styles/animations";
// // @ts-ignore
// import ProjectsMDX from "../sections/projects";

import Sample1 from "../../../assets/samples2/sample_1_orig.mp4";
import Sample1Poster from "../../../assets/samples2/sample_1_poster.png";
import Sample2 from "../../../assets/samples2/sample_2_orig.mp4";
import Sample2Poster from "../../../assets/samples2/sample_2_poster.png";
import Sample3 from "../../../assets/samples2/sample_3_orig.mp4";
import Sample3Poster from "../../../assets/samples2/sample_3_poster.png";

// const Projects = ({
//   offset,
//   factor = 2,
// }: {
//   offset: number;
//   factor?: number;
// }) => (
//   <div>
//     <Divider
//       bg="linear-gradient(to right, SlateBlue 0%, DeepSkyBlue 100%)"
//       sx={{ clipPath: `polygon(0 15%, 100% 25%, 100% 85%, 0 75%)` }}
//       speed={-0.2}
//       offset={1.1}
//       factor={factor}
//     />
//     <Content speed={0.4} offset={offset + 0.2} factor={factor}>
//       <Inner>
//         <h2 style={{ color: "white", fontSize: "54px" }}>Samples</h2>

//         <div
//           sx={{
//             display: `flex`,
//             // gridGap: [2, 2, 2, 2],
//             // gridTemplateColumns: [`1fr`, `1fr`, `repeat(2, 1fr)`],
//             // h2: { gridColumn: `-1/1`, color: `white !important` },
//           }}
//         >
//           <div>
//             <video autoPlay muted style={{ height: "20%" }}>
//               <source src={Video} type="video/mp4" />
//             </video>
//             <video autoPlay muted style={{ height: "20%" }}>
//               <source src={Video} type="video/mp4" />
//             </video>
//           </div>
//           <div>
//             <video autoPlay muted style={{ height: "20%" }}>
//               <source src={Video} type="video/mp4" />
//             </video>
//             <video autoPlay muted style={{ height: "20%" }}>
//               <source src={Video} type="video/mp4" />
//             </video>
//           </div>
//         </div>
//       </Inner>
//     </Content>
//     <Divider speed={0.1} offset={offset} factor={factor}>
//       <UpDown>
//         <Svg icon="box" width={6} color="icon_brightest" left="85%" top="75%" />
//         <Svg icon="upDown" width={8} color="icon_teal" left="70%" top="20%" />
//         <Svg
//           icon="triangle"
//           width={8}
//           stroke
//           color="icon_orange"
//           left="25%"
//           top="5%"
//         />
//         <Svg
//           icon="circle"
//           hiddenMobile
//           width={24}
//           color="icon_brightest"
//           left="17%"
//           top="60%"
//         />
//       </UpDown>
//       <UpDownWide>
//         <Svg
//           icon="arrowUp"
//           hiddenMobile
//           width={16}
//           color="icon_green"
//           left="20%"
//           top="90%"
//         />
//         <Svg
//           icon="triangle"
//           width={12}
//           stroke
//           color="icon_brightest"
//           left="90%"
//           top="30%"
//         />
//         <Svg
//           icon="circle"
//           width={16}
//           color="icon_yellow"
//           left="70%"
//           top="90%"
//         />
//         <Svg
//           icon="triangle"
//           hiddenMobile
//           width={16}
//           stroke
//           color="icon_teal"
//           left="18%"
//           top="75%"
//         />
//         <Svg
//           icon="circle"
//           width={6}
//           color="icon_brightest"
//           left="75%"
//           top="10%"
//         />
//         <Svg
//           icon="upDown"
//           hiddenMobile
//           width={8}
//           color="icon_green"
//           left="45%"
//           top="10%"
//         />
//       </UpDownWide>
//       <Svg
//         icon="circle"
//         hiddenMobile
//         width={6}
//         color="icon_brightest"
//         left="4%"
//         top="20%"
//       />
//       <Svg icon="circle" width={12} color="icon_pink" left="80%" top="60%" />
//       <Svg icon="box" width={6} color="icon_orange" left="10%" top="10%" />
//       <Svg icon="box" width={12} color="icon_yellow" left="29%" top="26%" />
//       <Svg
//         icon="hexa"
//         width={16}
//         stroke
//         color="icon_red"
//         left="75%"
//         top="30%"
//       />
//       <Svg
//         icon="hexa"
//         width={8}
//         stroke
//         color="icon_yellow"
//         left="80%"
//         top="70%"
//       />
//     </Divider>
//   </div>
// );

// export default Projects;

// /** @jsx jsx */
// import { jsx } from "theme-ui";
import Divider from "../elements/divider";
import Inner from "../elements/inner";
import Content from "../elements/content";
import Svg from "./svg";
import { UpDown, UpDownWide } from "../styles/animations";
// @ts-ignore
import ProjectsMDX from "../sections/projects";
import ProjectCard from "./project-card";
import Sample from "./sample";
import { useDeviceDetect } from "../elements/utils";
import SidebarWidthContext from "../elements/context";

const Projects = ({
  offset,
  factor = 2,
}: {
  offset: number;
  factor?: number;
}) => {
  const { isMobile } = useDeviceDetect();
  const sidebarWidth = React.useContext(SidebarWidthContext);

  if (isMobile === null) {
    return null;
  }
  return (
    <div>
      <Divider
        bg={`linear-gradient(to right, #333333 ${sidebarWidth}%, #888888 100%)`}
        sx={{
          clipPath: isMobile
            ? "polygon(0 5%, 100% 10%, 100% 100%, 0 95%)"
            : `polygon(0 15%, 100% 25%, 100% 100%, 0 90%)`,
        }}
        speed={-0.3}
        offset={1}
        factor={factor}
      />
      <Content
        speed={0.05}
        offset={offset + (isMobile ? 0.3 : 0.2)}
        factor={factor}
      >
        <Inner>
          <div
            style={
              {
                // padding: "3em 0"
              }
            }
          >
            <h2
              style={{
                fontSize: "58px",
                color: "white",
                textAlign: "center",
                margin: "0.5em",
              }}
            >
              Our Collection
            </h2>
          </div>
          <div
            style={{
              color: "white",
              fontSize: isMobile ? "14pt" : "16pt",
              fontWeight: isMobile ? "400" : "500",
              textAlign: "center",
              lineHeight: isMobile ? 1.4 : undefined,
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div style={{width: "95%"}}>
              Each NFT consists of a circular spectrogram, computed from a
              unique animal sound. The spectrogram breaks down the sound into
              its base frequencies, providing a Soundprint which reveals the
              hidden mathematical beauty of the animals's call.
            </div>
          </div>
          <div
            style={{
              color: "white",
              fontSize: isMobile ? "14pt" : "16pt",
              fontWeight: isMobile ? "400" : "500",
              textAlign: "center",
              marginTop: "2em",
              lineHeight: isMobile ? 1.4 : undefined,
            }}
          >
            {
              "We will mobilise the NFT community in support of wildlife conservation by 1. donating $200,000 from our proceeds to partnered charities and 2. splitting royalties from the secondary market 50% to charity and 50% to the artists."
            }

            {/* Don't let these be their last calls. */}
          </div>
          <div
            style={{
              flexDirection: isMobile ? "column" : "row",
              display: "flex",
              marginTop: "1em",
            }}
          >
            <Sample src={Sample1} poster={Sample1Poster} isEnd={isMobile} />
            <Sample src={Sample2} poster={Sample2Poster} isEnd={isMobile} />
            <Sample src={Sample3} poster={Sample3Poster} isEnd={true} />
          </div>
          {/* <ProjectCard
            title=""
            link=""
            bg="white"
          >
            <video  muted style={{ width: "100%" }} poster = {VideoPoster}>
              <source src={Video} type="video/mp4" />
            </video>
          </ProjectCard>
          <ProjectCard
            title=""
            link=""
            bg="white"
          >
            <video  muted style={{ width: "100%" }} poster = {VideoPoster}>
              <source src={Video} type="video/mp4" />
            </video>
          </ProjectCard>
          <ProjectCard
            title=""
            link=""
            bg="white"
          >
            <video  muted style={{ width: "100%" }} poster = {VideoPoster}>
              <source src={Video} type="video/mp4" />
            </video>
          </ProjectCard> */}
        </Inner>
      </Content>
      <Divider speed={0.1} offset={offset} factor={factor}>
        <UpDown>
          <Svg
            icon="box"
            width={6}
            color="icon_brightest"
            left="85%"
            top="75%"
          />
          <Svg icon="upDown" width={8} color="icon_teal" left="70%" top="20%" />
          <Svg
            icon="triangle"
            width={8}
            stroke
            color="icon_orange"
            left="25%"
            top="5%"
          />
          <Svg
            icon="circle"
            hiddenMobile
            width={24}
            color="icon_brightest"
            left="17%"
            top="60%"
          />
        </UpDown>
        <UpDownWide>
          <Svg
            icon="arrowUp"
            hiddenMobile
            width={16}
            color="icon_green"
            left="20%"
            top="90%"
          />
          <Svg
            icon="triangle"
            width={12}
            stroke
            color="icon_brightest"
            left="90%"
            top="30%"
          />
          <Svg
            icon="circle"
            width={16}
            color="icon_yellow"
            left="70%"
            top="90%"
          />
          <Svg
            icon="triangle"
            hiddenMobile
            width={16}
            stroke
            color="icon_teal"
            left="18%"
            top="75%"
          />
          <Svg
            icon="circle"
            width={6}
            color="icon_brightest"
            left="75%"
            top="10%"
          />
          <Svg
            icon="upDown"
            hiddenMobile
            width={8}
            color="icon_green"
            left="45%"
            top="10%"
          />
        </UpDownWide>
        <Svg
          icon="circle"
          hiddenMobile
          width={6}
          color="icon_brightest"
          left="4%"
          top="20%"
        />
        <Svg icon="circle" width={12} color="icon_pink" left="80%" top="60%" />
        <Svg icon="box" width={6} color="icon_orange" left="10%" top="10%" />
        <Svg icon="box" width={12} color="icon_yellow" left="29%" top="26%" />
        <Svg
          icon="hexa"
          width={16}
          stroke
          color="icon_red"
          left="75%"
          top="30%"
        />
        <Svg
          icon="hexa"
          width={8}
          stroke
          color="icon_yellow"
          left="80%"
          top="70%"
        />
      </Divider>
    </div>
  );
};

export default Projects;
